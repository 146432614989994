<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    viewBox="0 -960 960 960"
    width="24"
  >
    <path
      d="M260-420h440v-40H260v40Zm0-120h440v-40H260v40Zm0-120h440v-40H260v40Zm580 503.077L716.923-280H184.615Q157-280 138.5-298.5 120-317 120-344.615v-430.77Q120-803 138.5-821.5 157-840 184.615-840h590.77Q803-840 821.5-821.5 840-803 840-775.385v618.462ZM184.615-320H734l66 65.769v-521.154q0-9.23-7.692-16.923Q784.615-800 775.385-800h-590.77q-9.23 0-16.923 7.692Q160-784.615 160-775.385v430.77q0 9.23 7.692 16.923Q175.385-320 184.615-320ZM160-320v-480 480Z"
    />
  </svg>
</template>
