<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    viewBox="0 -960 960 960"
    width="24"
  >
    <path
      d="m296-371.923-28.308-28.308L480-612.539l212.308 212.308L664-371.923l-184-184-184 184Z"
    />
  </svg>
</template>
