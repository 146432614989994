<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    viewBox="0 -960 960 960"
    width="24"
  >
    <path
      fill="currentColor"
      d="M440-440ZM144.615-160Q117-160 98.5-178.5 80-197 80-224.615v-430.77Q80-683 98.5-701.5 117-720 144.615-720h118.308l74-80h207.692v40H354.231l-73.769 80H144.615q-10.769 0-17.692 6.923T120-655.385v430.77q0 10.769 6.923 17.692T144.615-200h590.77q10.769 0 17.692-6.923T760-224.615v-320h40v320Q800-197 781.5-178.5 763-160 735.385-160h-590.77ZM760-680v-80h-80v-40h80v-80h40v80h80v40h-80v80h-40ZM440-290.769q62.692 0 105.962-43.269 43.269-43.27 43.269-105.962t-43.269-105.962Q502.692-589.231 440-589.231t-105.962 43.269Q290.769-502.692 290.769-440t43.269 105.962q43.27 43.269 105.962 43.269Zm0-40q-46.615 0-77.923-31.308T330.769-440q0-46.615 31.308-77.923T440-549.231q46.615 0 77.923 31.308T549.231-440q0 46.615-31.308 77.923T440-330.769Z"
    />
  </svg>
</template>
