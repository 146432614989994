<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    viewBox="0 -960 960 960"
    width="24"
  >
    <path
      d="M260-135.384q-27.616 0-46.116-18.5T195.384-200v-455.385h40V-200q0 9.231 7.693 16.923 7.692 7.692 16.923 7.692h335.385v40.001H260Zm120-120.001q-27.616 0-46.116-18.5T315.384-320v-430.77q0-27.615 18.5-46.115 18.5-18.5 46.116-18.5h310.769q27.616 0 46.116 18.5t18.5 46.115V-320q0 27.615-18.5 46.115-18.5 18.5-46.116 18.5H380Zm0-40h310.769q9.231 0 16.923-7.692 7.693-7.692 7.693-16.923v-430.77q0-9.23-7.693-16.923-7.692-7.692-16.923-7.692H380q-9.231 0-16.923 7.692-7.692 7.693-7.692 16.923V-320q0 9.231 7.692 16.923 7.692 7.692 16.923 7.692Zm-24.615 0v-480 480Z"
    />
  </svg>
</template>
