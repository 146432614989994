<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    viewBox="0 -960 960 960"
    width="24"
  >
    <path
      fill="currentColor"
      d="M380-140v-594.923l-84 84-28.308-28.308L400-811.539l132.308 132.308L504-650.923l-84-84v364.307q32.692-48.692 80.154-72.576 47.461-23.885 104.538-23.885 24.846 0 47.269 1.769T694.923-460l-84-84 28.308-28.308L771.539-440 639.231-307.692 610.923-336l84-84q-21-3.538-44.808-5.808-23.807-2.269-46.346-2.269-60.154 0-111.807 35.885Q440.308-356.308 420-283.077V-140h-40Z"
    />
  </svg>
</template>
