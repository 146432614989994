<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    viewBox="0 -960 960 960"
    width="24"
  >
    <path
      d="M40-256.923v-34.539q0-36.846 39.384-61.154 39.385-24.307 101.385-24.307 9.154 0 19.616.884 10.461.885 22.23 2.885-9.384 16.385-13.692 33.615-4.308 17.231-4.308 34.539v48.077H40Zm240 0v-45q0-24.181 14.038-44.206 14.039-20.025 41.423-34.64 27.385-14.616 63.808-21.924Q435.692-410 479.692-410q44.847 0 81.27 7.307 36.423 7.308 63.807 21.924 27.385 14.615 41.308 34.64Q680-326.104 680-301.923v45H280Zm475.385 0v-47.847q0-19.139-4.193-36.069-4.192-16.93-12.577-32.315 12.539-2 22.5-2.885 9.962-.884 18.885-.884 62 0 101 24.192t39 61.269v34.539H755.385Zm-434.231-40h317.923v-8.462q-2.308-28.461-46.654-46.538Q548.077-370 480-370t-112.423 18.077q-44.346 18.077-46.423 46.538v8.462ZM180.565-414.616q-23.565 0-40.142-16.721t-16.577-40.202q0-23.231 16.721-39.692 16.721-16.462 40.202-16.462 23.231 0 40.077 16.462 16.846 16.461 16.846 39.922 0 22.77-16.424 39.732-16.424 16.961-40.703 16.961Zm599.435 0q-23 0-39.962-16.961-16.961-16.962-16.961-39.732 0-23.461 16.961-39.922 16.962-16.462 40.111-16.462 23.851 0 40.313 16.462 16.461 16.461 16.461 39.692 0 23.481-16.365 40.202-16.365 16.721-40.558 16.721ZM480.271-440q-36.425 0-62.194-25.577-25.77-25.577-25.77-62.116 0-37.269 25.577-62.481 25.577-25.211 62.116-25.211 37.269 0 62.481 25.133 25.212 25.134 25.212 62.288 0 36.425-25.134 62.194Q517.425-440 480.271-440Zm.114-40q19.692 0 33.5-14.193 13.807-14.192 13.807-33.884 0-19.693-13.711-33.5-13.712-13.808-33.981-13.808-19.308 0-33.5 13.712-14.192 13.711-14.192 33.98 0 19.308 14.192 33.5Q460.692-480 480.385-480Zm-.154 183.077ZM480-527.693Z"
    />
  </svg>
</template>
