<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24px"
    viewBox="0 -960 960 960"
    width="24px"
  >
    <path
      fill="currentColor"
      d="m263.38-267.69 95.85-166.93q-6.92 3.85-16.15 5.39-9.23 1.54-18.46 1.54-56 0-94.16-38.87-38.15-38.86-38.15-93.44 0-56 38.15-94.15 38.16-38.16 94.16-38.16 54.57 0 93.44 38.16 38.86 38.15 38.86 93.88 0 19.42-4.73 36.23-4.73 16.81-13.42 31.73L309.23-267.69h-45.85Zm350.77 0L710-434.62q-6.92 3.85-16.15 5.39-9.23 1.54-18.47 1.54-56 0-94.15-38.87-38.15-38.86-38.15-93.44 0-56.77 38.15-94.54t94.15-37.77q54.58 0 93.45 38.16 38.86 38.15 38.86 93.88 0 19.42-4.73 36.23-4.73 16.81-13.42 31.73L660-267.69h-45.85ZM324.62-472.31q36.53 0 62.11-25.57 25.58-25.58 25.58-62.12t-25.58-62.12q-25.58-25.57-62.11-25.57-36.54 0-62.12 25.57-25.58 25.58-25.58 62.12t25.58 62.12q25.58 25.57 62.12 25.57Zm350.76 0q36.54 0 62.12-25.57 25.58-25.58 25.58-62.12t-25.58-62.12q-25.58-25.57-62.12-25.57-36.53 0-62.11 25.57-25.58 25.58-25.58 62.12t25.58 62.12q25.58 25.57 62.11 25.57Zm0-87.69Zm-350.76 0Z"
    />
  </svg>
</template>
