<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    viewBox="0 -960 960 960"
    width="24"
  >
    <path
      fill="currentColor"
      d="M506-146v-49l204-204 49 49-204 204h-49ZM146-346v-28h280v28H146Zm641-32-49-49 29-29q5-5 10-5t10 5l29 29q5 5 5 10t-5 10l-29 29ZM146-506v-28h440v28H146Zm0-160v-28h440v28H146Z"
    />
  </svg>
</template>
