<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    viewBox="0 -960 960 960"
    width="24"
  >
    <path
      d="M184.615-200Q157-200 138.5-218.5 120-237 120-264.615v-84.616h40v84.616q0 9.23 7.692 16.923Q175.385-240 184.615-240h590.77q9.23 0 16.923-7.692Q800-255.385 800-264.615v-430.77q0-9.23-7.692-16.923Q784.615-720 775.385-720h-590.77q-9.23 0-16.923 7.692Q160-704.615 160-695.385v84.616h-40v-84.616Q120-723 138.5-741.5 157-760 184.615-760h590.77Q803-760 821.5-741.5 840-723 840-695.385v430.77Q840-237 821.5-218.5 803-200 775.385-200h-590.77Zm290.77-143.077-28.308-28.769L535.461-460H120v-40h415.461l-88.384-88.154 28.308-28.769L612.308-480 475.385-343.077Z"
    />
  </svg>
</template>
