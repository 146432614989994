<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    viewBox="0 -960 960 960"
    width="24"
  >
    <path
      d="M480-120q-74.539 0-140.231-28.423t-114.308-77.038q-48.615-48.616-77.038-114.308Q120-405.461 120-480t28.423-140.231q28.423-65.692 77.038-114.308 48.616-48.615 114.308-77.038Q405.461-840 480-840t140.231 28.423q65.692 28.423 114.308 77.038 48.615 48.616 77.038 114.308Q840-554.539 840-480t-28.423 140.231q-28.423 65.692-77.038 114.308-48.616 48.615-114.308 77.038Q554.539-120 480-120Zm0-40q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Zm-35.385 180h70.77q26.846 0 45.73-18.885Q580-337.769 580-364.615v-66.154q0-18.846-10.961-34.039Q558.077-480 540-480q18.077 0 29.039-15.192Q580-510.385 580-529.231v-66.154q0-26.846-18.885-45.73Q542.231-660 515.385-660h-70.77q-26.846 0-45.73 18.885Q380-622.231 380-595.385v66.154q0 18.846 10.961 34.039Q401.923-480 420-480q-18.077 0-29.039 15.192Q380-449.615 380-430.769v66.154q0 26.846 18.885 45.73Q417.769-300 444.615-300Zm0-320h70.77q10.769 0 17.692 6.923T540-595.385v70.77q0 10.769-6.923 17.692T515.385-500h-70.77q-10.769 0-17.692-6.923T420-524.615v-70.77q0-10.769 6.923-17.692T444.615-620Zm0 280q-10.769 0-17.692-6.923T420-364.615v-70.77q0-10.769 6.923-17.692T444.615-460h70.77q10.769 0 17.692 6.923T540-435.385v70.77q0 10.769-6.923 17.692T515.385-340h-70.77Z"
    />
  </svg>
</template>
