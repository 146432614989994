<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    viewBox="0 -960 960 960"
    width="24"
  >
    <path
      fill="currentColor"
      d="M266-267.692 237.692-296l183.769-184-183.769-184L266-692.308 478.308-480 266-267.692Zm244 0L481.692-296l183.769-184-183.769-184L510-692.308 722.308-480 510-267.692Z"
    />
  </svg>
</template>
