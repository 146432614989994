<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    viewBox="0 -960 960 960"
    width="24"
  >
    <path
      fill="currentColor"
      d="M438-162q44-74 109-103t113-29q20 0 37.5 2.5T732-284q27-33 47.5-84.5T800-480q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 54 16.5 101.5T220-292q37-23 73.5-32.5T371-334q23 0 45 3.5t33 6.5q-8 6-14 11t-13 12q-6-2-21.5-3.5T371-306q-35.032 0-69.516 9Q267-288 238-270q39 45 91 72t109 36Zm42.174 30Q408-132 344.442-159.391q-63.559-27.392-110.575-74.348-47.015-46.957-74.441-110.435Q132-407.652 132-479.826q0-72.174 27.391-135.732 27.392-63.559 74.348-110.574 46.957-47.016 110.435-74.442Q407.652-828 479.826-828q72.174 0 135.732 27.391 63.559 27.392 110.574 74.348 47.016 46.957 74.442 110.435Q828-552.348 828-480.174q0 72.174-27.391 135.732-27.392 63.559-74.348 110.575-46.957 47.015-110.435 74.441Q552.348-132 480.174-132ZM380-446q-48 0-81-33t-33-81q0-48 33-81t81-33q48 0 81 33t33 81q0 48-33 81t-81 33Zm0-28q35 0 60.5-25.5T466-560q0-35-25.5-60.5T380-646q-35 0-60.5 25.5T294-560q0 35 25.5 60.5T380-474Zm280 88q-39.48 0-66.74-27.26Q566-440.52 566-480q0-39.48 27.26-66.74Q620.52-574 660-574q39.48 0 66.74 27.26Q754-519.48 754-480q0 39.48-27.26 66.74Q699.48-386 660-386Zm-180-94Z"
    />
  </svg>
</template>
