<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    viewBox="0 -960 960 960"
    width="24"
  >
    <path
      fill="currentColor"
      d="M260-140v-120H140v-40h120v-160H140v-40h120v-160H140v-40h120v-120h40v120h160v-120h40v120h160v-120h40v120h120v40H700v160h120v40H700v160h120v40H700v120h-40v-120H500v120h-40v-120H300v120h-40Zm40-160h160v-160H300v160Zm200 0h160v-160H500v160ZM300-500h160v-160H300v160Zm200 0h160v-160H500v160Z"
    />
  </svg>
</template>
