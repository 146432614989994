<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    viewBox="0 -960 960 960"
    width="24"
  >
    <path
      d="M480.134-120q-74.673 0-140.41-28.339-65.737-28.34-114.365-76.922-48.627-48.582-76.993-114.257Q120-405.194 120-479.866q0-74.673 28.339-140.41 28.34-65.737 76.922-114.365 48.582-48.627 114.257-76.993Q405.194-840 479.866-840q74.673 0 140.41 28.339 65.737 28.34 114.365 76.922 48.627 48.582 76.993 114.257Q840-554.806 840-480.134q0 74.673-28.339 140.41-28.34 65.737-76.922 114.365-48.582 48.627-114.257 76.993Q554.806-120 480.134-120ZM480-160q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320ZM380-300h135.13q27.87 0 46.37-18.5Q580-337 580-364.615v-66.154q0-23.692-12.385-35.308Q555.231-477.692 540-480q15.231-2.308 27.615-13.923Q580-505.539 580-529.231v-66.154Q580-623 561.5-641.5 543-660 515.13-660H380v40h135.385q10.769 0 17.692 6.923T540-595.385v70.77q0 10.769-6.923 17.692T515.385-500h-70.77v40h70.77q10.769 0 17.692 6.923T540-435.385v70.77q0 10.769-6.923 17.692T515.385-340H380v40Z"
    />
  </svg>
</template>
