<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    viewBox="0 -960 960 960"
    width="24"
  >
    <path
      d="M480.134-120q-74.673 0-140.41-28.339-65.737-28.34-114.365-76.922-48.627-48.582-76.993-114.257Q120-405.194 120-479.866q0-74.673 28.339-140.41 28.34-65.737 76.922-114.365 48.582-48.627 114.257-76.993Q405.194-840 479.866-840q74.673 0 140.41 28.339 65.737 28.34 114.365 76.922 48.627 48.582 76.993 114.257Q840-554.806 840-480.134q0 74.673-28.339 140.41-28.34 65.737-76.922 114.365-48.582 48.627-114.257 76.993Q554.806-120 480.134-120ZM480-160q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Zm-35.385 180h70.77Q543-300 561.5-318.5 580-337 580-364.615v-70.77Q580-463 561.5-481.5 543-500 515.385-500H420v-95.385q0-10.769 6.923-17.692T444.615-620H540v-40h-95.385Q417-660 398.5-641.5 380-623 380-595.385v230.77Q380-337 398.5-318.5 417-300 444.615-300ZM420-460h95.385q10.769 0 17.692 6.923T540-435.385v70.77q0 10.769-6.923 17.692T515.385-340h-70.77q-10.769 0-17.692-6.923T420-364.615V-460Z"
    />
  </svg>
</template>
