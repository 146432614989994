<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    viewBox="0 -960 960 960"
    width="24"
  >
    <path
      fill="currentColor"
      d="M264.615-200Q237-200 218.5-218.5 200-237 200-264.615V-360h40v95.385q0 9.23 7.692 16.923Q255.385-240 264.615-240h430.77q9.23 0 16.923-7.692Q720-255.385 720-264.615V-360h40v95.385Q760-237 741.5-218.5 723-200 695.385-200h-430.77ZM480-350.769 338.461-492.308l28.308-28.769L460-427.846v-346h40v346l93.231-93.231 28.308 28.769L480-350.769Z"
    />
  </svg>
</template>
