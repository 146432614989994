<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    viewBox="0 -960 960 960"
    width="24"
  >
    <path
      fill="currentColor"
      d="M160-220v-520l616.924 260L160-220Zm40-60 474-200-474-200v155.385L393.846-480 200-435.385V-280Zm0 0v-400 400Z"
    />
  </svg>
</template>
