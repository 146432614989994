<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    viewBox="0 -960 960 960"
    width="24"
  >
    <path
      d="m800-274-40-40v-421.385q0-9.23-7.692-16.923Q744.615-760 735.385-760H314l-40-40h461.385Q763-800 781.5-781.5 800-763 800-735.385V-274Zm19.692 190.308L743.385-160h-518.77Q197-160 178.5-178.5 160-197 160-224.615v-518.77l-76.308-76.307L112-848l736 736-28.308 28.308ZM300-300l78.461-103.077 70.001 84.615L509.154-394 200-703.154v478.539q0 9.23 7.692 16.923Q215.385-200 224.615-200h478.539l-100-100H300Zm237-237Zm-85.308 85.308Z"
    />
  </svg>
</template>
