<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    viewBox="0 -960 960 960"
    width="24"
  >
    <path
      fill="currentColor"
      d="M376-267.692 347.692-296l184-184-184-184L376-692.308 588.308-480 376-267.692Z"
    />
  </svg>
</template>
