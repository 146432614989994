<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    viewBox="0 -960 960 960"
    width="24"
  >
    <path
      fill="currentColor"
      d="M460-743.077v-135.385h40v135.385h-40Zm219.846 91.539-27.308-27.308 94.308-98.077 29.077 29.308-96.077 96.077ZM743.077-460v-40h135.385v40H743.077ZM460-81.538v-134.616h40v134.616h-40ZM281.692-652.77l-98.615-94.077 30.077-28.308 96.077 95.308-27.539 27.077Zm464.923 469.692-94.077-98.077 27.078-26.308 95.538 93.847-28.539 30.538ZM81.538-460v-40h135.385v40H81.538Zm130.847 276.923-27.539-30.077 94.308-94.308 14.384 13.924 15.154 14.154-96.307 96.307ZM480.181-280q-83.258 0-141.72-58.28Q280-396.561 280-479.819q0-83.258 58.28-141.72Q396.561-680 479.819-680q83.258 0 141.72 58.28Q680-563.439 680-480.181q0 83.258-58.28 141.72Q563.439-280 480.181-280ZM480-320q66 0 113-47t47-113q0-66-47-113t-113-47q-66 0-113 47t-47 113q0 66 47 113t113 47Zm0-160Z"
    />
  </svg>
</template>
