<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    viewBox="0 -960 960 960"
    width="24"
  >
    <path
      fill="currentColor"
      d="M297.538-256.923H340l42.462-120.462h195.076L620-256.923h42.462L497.385-712.308h-34.77L297.538-256.923Zm98.154-158.154L478-646.923h4l82.308 231.846H395.692ZM184.615-120Q157-120 138.5-138.5 120-157 120-184.615v-590.77Q120-803 138.5-821.5 157-840 184.615-840h590.77Q803-840 821.5-821.5 840-803 840-775.385v590.77Q840-157 821.5-138.5 803-120 775.385-120h-590.77Zm0-40h590.77q9.23 0 16.923-7.692Q800-175.385 800-184.615v-590.77q0-9.23-7.692-16.923Q784.615-800 775.385-800h-590.77q-9.23 0-16.923 7.692Q160-784.615 160-775.385v590.77q0 9.23 7.692 16.923Q175.385-160 184.615-160ZM160-800v640-640Z"
    />
  </svg>
</template>
