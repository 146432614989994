<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    viewBox="0 -960 960 960"
    width="24"
  >
    <path
      fill="currentColor"
      d="M450-267.692 237.692-480 450-692.308 478.308-664 294.539-480l183.769 184L450-267.692Zm244 0L481.692-480 694-692.308 722.308-664 538.539-480l183.769 184L694-267.692Z"
    />
  </svg>
</template>
