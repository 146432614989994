<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    viewBox="0 -960 960 960"
    width="24"
  >
    <path
      fill="currentColor"
      d="M560-267.692 347.692-480 560-692.308 588.308-664l-184 184 184 184L560-267.692Z"
    />
  </svg>
</template>
