<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    viewBox="0 -960 960 960"
    width="24"
  >
    <path
      fill="currentColor"
      d="M320-240q-100.077 0-170.039-69.961Q80-379.923 80-480q0-100.077 69.961-170.039Q219.923-720 320-720q36.231 0 69.423 10.384 33.192 10.385 61.038 30.154-41.077 39-63.461 90.846Q364.615-536.769 364.615-480T387-371.384q22.384 51.846 63.461 90.846-27.846 19.769-61.038 30.154Q356.231-240 320-240Zm320 0q-36.231 0-69.423-10.384-33.192-10.385-61.038-30.154 7.769-7.077 14.692-15.116 6.923-8.038 13.769-16.038 21.77 15.077 47.539 23.384Q611.308-280 640-280q83 0 141.5-58.5T840-480q0-83-58.5-141.5T640-680q-28.692 0-54.461 8.308-25.769 8.307-47.539 23.384-6.846-8-13.769-16.038-6.923-8.039-14.692-15.116 27.846-19.769 61.038-30.154Q603.769-720 640-720q100.077 0 170.039 69.961Q880-580.077 880-480q0 100.077-69.961 170.039Q740.077-240 640-240Zm-160-62.307q-37.769-33.616-58.885-80Q400-428.692 400-480t21.115-97.693q21.116-46.384 58.885-80 37.769 33.616 58.885 80Q560-531.308 560-480t-21.115 97.693q-21.116 46.384-58.885 80Z"
    />
  </svg>
</template>
