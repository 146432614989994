<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    viewBox="0 -960 960 960"
    width="24"
  >
    <path
      d="M481.12-270.769q13.265 0 22.38-9.159 9.115-9.159 9.115-22.423 0-13.264-9.158-22.38-9.159-9.115-22.423-9.115-13.265 0-22.38 9.159-9.116 9.158-9.116 22.423 0 13.264 9.159 22.38 9.159 9.115 22.423 9.115Zm-20.659-132.462h38.616q1.538-26.077 9.808-42.384 8.269-16.308 34.038-41.616 26.769-26.769 39.846-47.961 13.077-21.193 13.077-49.059 0-47.288-33.229-75.365-33.23-28.077-78.617-28.077-43.154 0-73.269 23.462-30.116 23.462-44.116 53.923l36.77 15.231q9.615-21.846 27.5-38.615 17.884-16.77 51.577-16.77 38.923 0 56.846 21.347 17.923 21.346 17.923 46.961 0 20.769-11.231 37.115-11.231 16.347-29.231 32.885-34.769 32.077-45.538 54.385-10.77 22.307-10.77 54.538ZM480.134-120q-74.673 0-140.41-28.339-65.737-28.34-114.365-76.922-48.627-48.582-76.993-114.257Q120-405.194 120-479.866q0-74.673 28.339-140.41 28.34-65.737 76.922-114.365 48.582-48.627 114.257-76.993Q405.194-840 479.866-840q74.673 0 140.41 28.339 65.737 28.34 114.365 76.922 48.627 48.582 76.993 114.257Q840-554.806 840-480.134q0 74.673-28.339 140.41-28.34 65.737-76.922 114.365-48.582 48.627-114.257 76.993Q554.806-120 480.134-120ZM480-160q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"
    />
  </svg>
</template>
