<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    viewBox="0 -960 960 960"
    width="24"
  >
    <path
      fill="currentColor"
      d="M143.846-269.231v-40h480v40h-480Zm644-38.461L615.538-480l172.308-172.308L816.154-624l-144 144 144 144-28.308 28.308ZM143.846-460v-40h360v40h-360Zm0-190.769v-40h480v40h-480Z"
    />
  </svg>
</template>
