<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24px"
    viewBox="0 -960 960 960"
    width="24px"
  >
    <path
      fill="currentColor"
      d="M160-300v-360h40v160h200v-160h40v360h-40v-160H200v160h-40Zm360 0v-135.38q0-26.66 18.98-45.64T584.62-500h150.76q10.77 0 17.7-6.92 6.92-6.93 6.92-17.7v-70.76q0-10.77-6.92-17.7-6.93-6.92-17.7-6.92H520v-40h215.38q26.66 0 45.64 18.98T800-595.38v70.76q0 26.66-18.98 45.64T735.38-460H584.62q-10.77 0-17.7 6.92-6.92 6.93-6.92 17.7V-340h240v40H520Z"
    />
  </svg>
</template>
