<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24px"
    viewBox="0 -960 960 960"
    width="24px"
  >
    <path
      fill="currentColor"
      d="M160-120v-35.38h100V-210h-60v-35.38h60V-300H160v-35.38h107.69q11.77 0 19.73 7.96 7.96 7.96 7.96 19.73v55.38q0 11.77-7.96 19.73-7.96 7.96-19.73 7.96 11.77 0 19.73 7.97 7.96 7.96 7.96 19.73v49.23q0 11.77-7.96 19.73-7.96 7.96-19.73 7.96H160Zm0-252.31V-470q0-11.77 7.96-19.73 7.96-7.96 19.73-7.96H260v-54.62H160v-35.38h107.69q11.77 0 19.73 7.96 7.96 7.96 7.96 19.73v70q0 11.77-7.96 19.73-7.96 7.96-19.73 7.96h-72.31v54.62h100v35.38H160Zm60-252.31v-180h-60V-840h95.38v215.38H220ZM384.62-220v-40H800v40H384.62Zm0-240v-40H800v40H384.62Zm0-240v-40H800v40H384.62Z"
    />
  </svg>
</template>
