<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    viewBox="0 -960 960 960"
    width="24"
  >
    <path
      fill="currentColor"
      d="M140-269.231v-40h680v40H140ZM140-460v-40h680v40H140Zm0-190.769v-40h680v40H140Z"
    />
  </svg>
</template>
