<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    viewBox="0 -960 960 960"
    width="24"
  >
    <path
      fill="currentColor"
      d="M720.091-100q-41.63 0-70.86-29.167Q620-158.333 620-200q0-7.979 1.385-16.528 1.384-8.549 4.153-16.087L315.846-415.077q-14.692 16.539-34.154 25.808Q262.231-380 240-380q-41.667 0-70.833-29.14Q140-438.28 140-479.91q0-41.629 29.167-70.859Q198.333-580 240-580q22.231 0 41.692 9.269 19.462 9.269 34.154 25.808l309.692-182.462q-2.769-7.538-4.153-16.087Q620-752.021 620-760q0-41.667 29.14-70.833Q678.281-860 719.909-860q41.63 0 70.86 29.14Q820-801.719 820-760.091q0 41.63-29.167 70.86Q761.667-660 720-660q-22.231 0-41.692-9.269-19.462-9.269-34.154-25.808L334.462-512.615q2.769 7.538 4.154 16.033Q340-488.088 340-480.159q0 7.928-1.384 16.582-1.385 8.654-4.154 16.192l309.692 182.462q14.692-16.539 34.154-25.808Q697.769-300 720-300q41.667 0 70.833 29.14Q820-241.72 820-200.09q0 41.629-29.14 70.859Q761.719-100 720.091-100ZM720-700q24.692 0 42.346-17.654Q780-735.308 780-760q0-24.692-17.654-42.346Q744.692-820 720-820q-24.692 0-42.346 17.654Q660-784.692 660-760q0 24.692 17.654 42.346Q695.308-700 720-700ZM240-420q24.692 0 42.346-17.654Q300-455.308 300-480q0-24.692-17.654-42.346Q264.692-540 240-540q-24.692 0-42.346 17.654Q180-504.692 180-480q0 24.692 17.654 42.346Q215.308-420 240-420Zm480 280q24.692 0 42.346-17.654Q780-175.308 780-200q0-24.692-17.654-42.346Q744.692-260 720-260q-24.692 0-42.346 17.654Q660-224.692 660-200q0 24.692 17.654 42.346Q695.308-140 720-140Zm0-620ZM240-480Zm480 280Z"
    />
  </svg>
</template>
