<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    viewBox="0 -960 960 960"
    width="24"
  >
    <path
      fill="currentColor"
      d="M212.308-200h42.923L651-595.769l-42.923-42.923-395.769 395.769V-200Zm524.308-424.538-100.001-99.539 48.308-48.308q12.231-12.231 29.192-12.231 16.962 0 29.193 12.231L784.693-731q12.23 12.231 12.461 28.961.231 16.731-12 28.962l-48.538 48.539Zm-28.77 29L272.308-160H172.307v-100l435.539-435.539 100 100.001Zm-78.692-22.077-21.077-21.077L651-595.769l-21.846-21.846Z"
    />
  </svg>
</template>
