<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    viewBox="0 -960 960 960"
    width="24"
  >
    <path
      d="M380-300h200v-40H420v-95.385q0-10.769 6.923-17.692T444.615-460h70.77Q543-460 561.5-478.5 580-497 580-524.615v-70.77Q580-623 561.5-641.5 543-660 515.385-660H380v40h135.385q10.769 0 17.692 6.923T540-595.385v70.77q0 10.769-6.923 17.692T515.385-500h-70.77Q417-500 398.5-481.5 380-463 380-435.385V-300ZM224.615-160Q197-160 178.5-178.5 160-197 160-224.615v-510.77Q160-763 178.5-781.5 197-800 224.615-800h510.77Q763-800 781.5-781.5 800-763 800-735.385v510.77Q800-197 781.5-178.5 763-160 735.385-160h-510.77Zm0-40h510.77q9.23 0 16.923-7.692Q760-215.385 760-224.615v-510.77q0-9.23-7.692-16.923Q744.615-760 735.385-760h-510.77q-9.23 0-16.923 7.692Q200-744.615 200-735.385v510.77q0 9.23 7.692 16.923Q215.385-200 224.615-200ZM200-760v560-560Z"
    />
  </svg>
</template>
