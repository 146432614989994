<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    viewBox="0 -960 960 960"
    width="24"
  >
    <path
      fill="currentColor"
      d="M358.154-160H200q-16.5 0-28.25-11.75T160-200v-158.154q34.154-10 57.077-37.807Q240-423.769 240-460t-22.923-64.039Q194.154-551.846 160-561.846V-720q0-16.5 11.75-28.25T200-760h160q10.769-34.308 37.846-54.846 27.077-20.539 62.154-20.539t62.154 20.539Q549.231-794.308 560-760h160q16.5 0 28.25 11.75T760-720v160q34.308 10.769 54.846 37.846 20.539 27.077 20.539 62.154t-20.539 62.154Q794.308-370.769 760-360v160q0 16.5-11.75 28.25T720-160H561.846q-10.769-36.154-38.807-58.077Q495-240 460-240t-63.039 21.923Q368.923-196.154 358.154-160ZM200-200h131.923q17.077-39.846 52.769-59.923Q420.385-280 460-280q39.615 0 75.308 20.077Q571-239.846 588.077-200H720v-195.385h18.462q28.769-4.615 42.846-23.692 14.077-19.077 14.077-40.923t-14.077-40.923Q767.231-520 738.462-524.615H720V-720H524.615v-18.462q-4.615-28.769-23.692-42.846-19.077-14.077-40.923-14.077t-40.923 14.077Q400-767.231 395.385-738.462V-720H200v131.077q37.077 17.692 58.539 52.769Q280-501.077 280-459.759q0 40.605-21.461 75.682-21.462 35.077-58.539 53V-200Zm297.692-297.692Z"
    />
  </svg>
</template>
