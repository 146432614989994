<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g>
      <path
        d="M24,12A12,12,0,1,0,10.125,23.854V15.469H7.078V12h3.047V9.356c0-3.008,1.792-4.669,4.533-4.669a18.453,18.453,0,0,1,2.686.234V7.875H15.831a1.734,1.734,0,0,0-1.956,1.874V12H17.2l-.532,3.469h-2.8v8.385A12,12,0,0,0,24,12"
        fill="#1877f2"
      />
      <path
        d="M311.593,210.781l.532-3.469H308.8v-2.251a1.734,1.734,0,0,1,1.956-1.874h1.513v-2.953a18.453,18.453,0,0,0-2.686-.234c-2.741,0-4.533,1.661-4.533,4.669v2.644H302v3.469h3.047v8.385a12.127,12.127,0,0,0,3.75,0v-8.385Z"
        transform="translate(-294.922 -195.313)"
        fill="#fff"
      />
    </g>
  </svg>
</template>
